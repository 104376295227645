<template>
    <div class="h-100">
        <el-container class="h-100">
            <el-aside v-show="organizations.length>1" width="320px" class="bg-white border-right">
                <el-container class="h-100">
                    <el-header height="auto" class="p-3 border-bottom border-light">组织机构</el-header>
                    <el-main class="p-0 narrow-scrollbar">
                        <el-tree ref="organizationTree" :data="organizationTreeNodes" node-key="value" highlight-current
                            default-expand-all :expand-on-click-node="false"
                            @current-change="selectOrganizationTreeNode" />
                    </el-main>
                </el-container>
            </el-aside>
            <el-main>
                <el-card shadow="never">
                    <div class="row no-gutters">
                        <div class="col">
                            <search-box v-model="key" style="width: 280px;" @search="searchUsers" />
                        </div>
                        <div class="col-auto">
                            <el-button type="primary" @click="showUserDialog(null)">添加</el-button>
                            <el-button @click="importUsersDialogVisible=true">导入</el-button>
                        </div>
                    </div>
                    <el-table :data="users" class="mt-2">
                        <el-table-column prop="organizationName" label="组织机构" sortable />
                        <el-table-column prop="userName" label="用户名" sortable />
                        <el-table-column prop="name" label="姓名" sortable />
                        <el-table-column prop="sort" label="排序" sortable />
                        <el-table-column label="角色" width="100">
                            <template #default="scope">
                                <el-button type="text" @click="showUserRolesDialog(scope.row)">
                                    {{scope.row.roleIds.length}} 个
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" fixed="right" width="170">
                            <template #default="scope">
                                <el-button type="text" @click="resetUserPassword(scope.row.id)">重置密码</el-button>
                                <el-button type="text" @click="showUserDialog(scope.row)">修改</el-button>
                                <el-button type="text" class="text-danger" @click="deleteUser(scope.row.id)">
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-main>
        </el-container>

        <el-dialog :visible.sync="userDialogVisible" title="用户" :close-on-click-modal="false" width="640px">
            <el-form>
                <el-form-item label="组织机构" class="form-item-required">
                    <el-select v-model="user.organizationId" placeholder="组织机构">
                        <el-option v-for="organization in organizations" :key="organization.id" :value="organization.id"
                            :label="organization.name">
                            <span :style="{marginLeft: (organization.id.length-4)*4+'px'}">{{organization.name}}</span>
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="用户名" class="form-item-required">
                            <el-input v-model="user.userName" placeholder="用户名" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="姓名" class="form-item-required">
                            <el-input v-model="user.name" placeholder="姓名" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="电话">
                            <el-input v-model="user.phone" placeholder="电话" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="排序" class="form-item-required">
                            <el-input-number v-model="user.sort" controls-position="right" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="saveUser">确定</el-button>
                <el-button @click="userDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <el-dialog :visible.sync="userRolesDialogVisible" title="角色" :close-on-click-modal="false" width="400px">
            <el-form>
                <div style="margin-bottom: 10px;">
                    <div class="border rounded overflow-auto narrow-scrollbar" style="height: 320px;">
                        <el-checkbox-group v-model="user.roleIds">
                            <div v-for="role in roles" :key="role.id" class="mx-3 my-2">
                                <el-checkbox :disabled="role.level<$store.state.user.roleLevel" :label="role.id">
                                    {{role.name}}
                                </el-checkbox>
                            </div>
                        </el-checkbox-group>
                    </div>
                </div>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="updateUserRoles">确定</el-button>
                <el-button @click="userRolesDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <import-dialog v-model="importUsersDialogVisible" title="导入" action="/api/User/ImportUsers"
            template="/templates/用户导入模板.xls" @success="loadUsers" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                organizationTreeNodes: [],
                organizationTreeNode: null,
                organizations: [],
                roles: [],
                key: null,
                users: [],
                user: {},
                userDialogVisible: false,
                userRolesDialogVisible: false,
                importUsersDialogVisible: false,
            };
        },
        methods: {
            async loadOrganizationTreeNodes() {
                let response = await this.$axios.get('/api/Organization/GetOrganizationTreeNodes');
                this.organizationTreeNodes = response.data;
                if (this.organizationTreeNodes.length > 0) {
                    this.$nextTick(() => {
                        this.$refs.organizationTree.setCurrentKey(this.organizationTreeNodes[0].value);
                        this.selectOrganizationTreeNode(this.organizationTreeNodes[0]);
                    });
                }
            },
            selectOrganizationTreeNode(data) {
                this.organizationTreeNode = data;
                this.loadUsers();
            },
            async loadRoles() {
                let response = await this.$axios.get('/api/Role/GetRoles');
                this.roles = response.data;
            },
            async loadOrganizations() {
                let response = await this.$axios.get('/api/Organization/GetOrganizations');
                this.organizations = response.data;
            },
            async loadUsers() {
                let response = await this.$axios.get('/api/User/GetUsers', {
                    params: {
                        organizationId: this.organizationTreeNode.value,
                    }
                });
                this.users = response.data;
            },
            async searchUsers() {
                let response = await this.$axios.get('/api/User/GetUsers', {
                    params: {
                        key: this.key,
                    }
                });
                this.users = response.data;
            },
            showUserDialog(user) {
                if (user) {
                    this.user = JSON.parse(JSON.stringify(user));
                }
                else {
                    if (!this.organizationTreeNode) {
                        alert('请选择组织机构。');
                        return;
                    }

                    this.user = { organizationId: this.organizationTreeNode.value, sort: this.getNextNumber(this.users.map(p => p.sort)), roleIds: [] };
                }
                this.userDialogVisible = true;
            },
            getNextNumber(numbers) {
                if (numbers.length == 0) {
                    return 1;
                }

                return Math.max(...numbers) + 1;
            },
            async saveUser() {
                if (this.user.id == undefined) {
                    await this.$axios.post('/api/User/AddUser', {
                        organizationId: this.user.organizationId,
                        userName: this.user.userName,
                        name: this.user.name,
                        phone: this.user.phone,
                        sort: this.user.sort,
                    });
                    this.userDialogVisible = false;
                    this.loadUsers();
                }
                else {
                    await this.$axios.post('/api/User/UpdateUser', {
                        id: this.user.id,
                        organizationId: this.user.organizationId,
                        userName: this.user.userName,
                        name: this.user.name,
                        phone: this.user.phone,
                        sort: this.user.sort,
                    });
                    this.userDialogVisible = false;
                    this.loadUsers();
                }
            },
            async resetUserPassword(id) {
                if (!confirm('确定要将密码重置为“123456”吗？')) {
                    return;
                }

                await this.$axios.post('/api/User/ResetUserPassword', { id: id });
                this.$message.success('密码重置成功。');
            },
            showUserRolesDialog(user) {
                this.user = JSON.parse(JSON.stringify(user));
                this.userRolesDialogVisible = true;
            },
            async updateUserRoles() {
                await this.$axios.post('/api/User/UpdateUserRoles', {
                    id: this.user.id,
                    roleIds: this.user.roleIds,
                });
                this.userRolesDialogVisible = false;
                this.loadUsers();
            },
            async deleteUser(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/User/DeleteUser', { id: id });
                this.loadUsers();
            },
        },
        created() {
            this.loadOrganizationTreeNodes();
            this.loadRoles();
            this.loadOrganizations();
        },
    };
</script>